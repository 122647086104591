<template>
  <div>
    <ion-grid v-if="getMenuCategories.length" class="ion-padding">
      <ion-row>
        <ion-col
          @click="goToMenu(category.categoryname)"
          size="6"
          v-for="category in getMenuCategories"
          :key="category.id"
        >
          <div class="category-tile ion-padding">
            <ion-thumbnail>
              <img :src="category.icon" />
            </ion-thumbnail>
            <label>{{ category.categoryname }}</label>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
    <!-- Skeleton screen -->
    <div v-if="!getMenuCategories.length">
      <ion-grid ion-padding>
        <ion-row>
          <ion-col size="6" v-for="loader in loaderLoop" :key="loader.id">
            <div class="category-tile ion-padding">
              <ion-thumbnail>
                <ion-skeleton-text animated></ion-skeleton-text>
              </ion-thumbnail>
              <label><ion-skeleton-text animated></ion-skeleton-text></label>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { IonGrid, IonRow, IonCol, IonThumbnail, IonSkeletonText } from '@ionic/vue'
  export default {
    name: 'Restaurants',
    emits: ['categoryName'],
    props: {
      currentProperties: {
        type: Object,
      },
    },
    components: {
      IonGrid,
      IonRow,
      IonCol,
      IonThumbnail,
      IonSkeletonText,
    },
    data() {
      return {
        loaderLoop: [1, 2, 3, 4],
      }
    },
    methods: {
      capitalize(data) {
        let capitalized = []
        data.split(' ').forEach((word) => {
          capitalized.push(word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        })
        return capitalized.join(' ')
      },
      goToMenu(category) {
        this.$emit('categoryName', category)
      },
    },
    computed: {
      ...mapGetters(['getMenuCategories']),
    },
  }
</script>
<style lang="scss" scoped>
  ion-row {
    display: flex;
  }

  .category-tile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 7px;
    height: 100%;
    width: 100%;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    ion-thumbnail {
      height: 32px;
      width: 32px;
      margin: 0.2rem 0;
    }

    label {
      width: 80%;
      font-size: 14px;
      margin: 0.2rem 0;
    }
  }
</style>
